.title {
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  color: var(--card-title);

  &.f64 {
    font-size: 64px;
    @media (max-width: 1920px) {
      font-size: 60px;
    }
    @media (max-width: 1750px) {
      font-size: 56px;
    }
    @media (max-width: 1600px) {
      font-size: 52px;
    }
    @media (max-width: 1440px) {
      font-size: 48px;
    }
    @media (max-width: 1200px) {
      font-size: 44px;
    }
    @media (max-width: 1024px) {
      font-size: 40px;
    }
    @media (max-width: 820px) {
      font-size: 36px;
    }
    @media (max-width: 740px) {
      font-size: 32px;
    }
  }
  &.f32 {
    font-size: 32px;
    @media (max-width: 1550px) {
      font-size: 28px;
    }
    @media (max-width: 1400px) {
      font-size: 26px;
    }
    @media (max-width: 1200px) {
      font-size: 24px;
    }
    @media (max-width: 820px) {
      font-size: 22px;
    }
  }
  &.f24 {
    font-size: 24px;
    @media (max-width: 1550px) {
      font-size: 22px;
    }
    @media (max-width: 1400px) {
      font-size: 20px;
    }
    @media (max-width: 1200px) {
      font-size: 18px;
    }
    @media (max-width: 820px) {
      font-size: 17px;
    }
  }
  &.f18 {
    font-size: 18px;
    @media (max-width: 1550px) {
      font-size: 17px;
    }
    @media (max-width: 1400px) {
      font-size: 16px;
    }
  }
}
