:global(body .adyen-checkout-form-instruction) {
  padding-bottom: 24px;
  color: var(--default-text);
  @media (max-width: 600px) {
    padding-bottom: 16px;
  }
}
:global(body .adyen-checkout__input) {
  border: 1px solid var(--default-border);
  &::-webkit-input-placeholder {
    color: var(--placeholder);
  }
}

:global(body .adyen-checkout__label--focused .adyen-checkout__label__text),
:global(body .adyen-checkout__helper-text),
:global(body .adyen-checkout__label__text) {
  color: var(--default-text);
}

:global(body .adyen-checkout__input:active),
:global(body .adyen-checkout__input:focus),
:global(body .adyen-checkout__input--focus),
:global(body .adyen-checkout__input:active:hover),
:global(body .adyen-checkout__input:focus:hover),
:global(body .adyen-checkout__input--focus:hover) {
  border-color: var(--default-border);
  box-shadow: none;
}

:global(body .adyen-checkout__field--consentCheckbox) {
  background-color: var(--adyen-dark);
  border-color: var(--adyen-dark);
  color: var(--adyen-light);
  border-radius: 0;
}

:global(body .adyen-checkout__checkbox__label) {
  color: var(--adyen-light);
}

:global(
    body
      .adyen-checkout__checkbox__input:checked
      + .adyen-checkout__checkbox__label:after
  ) {
  background-color: var(--adyen-light);
  border-color: var(--adyen-light);
}

:global(
    body
      .adyen-checkout__checkbox__input:hover:not(:focus)
      + .adyen-checkout__checkbox__label:after
  ) {
  box-shadow: none;
  border-color: var(--adyen-light);
}

:global(
    body
      .adyen-checkout__checkbox__input
      + .adyen-checkout__checkbox__label:before
  ) {
  height: 10px;
  left: 0px;
}

:global(body .adyen-checkout__store-details) {
  :global(.adyen-checkout__checkbox__label) {
    color: var(--adyen-light);
  }
  :global(
      .adyen-checkout__checkbox__input:checked
        + .adyen-checkout__checkbox__label:after
    ) {
    background-color: var(--adyen-light);
    border-color: var(--adyen-light);
  }
  :global(
      .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:before
    ) {
    left: 1px;
  }
}

:global(body .adyen-checkout__threeds2__challenge) {
  min-height: 600px;
}

:global(body .adyen-checkout__fieldset__title) {
  color: var(--adyen-dark);
}
