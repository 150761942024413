@import "../assets/scss/_confirmPopup.scss";
@import "../assets/scss/_icon.scss";

.siteBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: var(--site-bgimg);
  .mobile {
    display: none;
  }
  .img {
    background-position: left top;
  }
  @media (max-width: 820px) {
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
}

.content {
  position: relative;
  z-index: 2;
  &.full {
    height: 100vh;
  }
  &.staticPage {
    min-height: 70vh;
    @media (max-width: 1600px) {
      min-height: 60vh;
    }
    @media (max-width: 820px) {
      min-height: 55vh;
    }
  }
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0px -15px 20px rgba(0, 0, 0, 0.5);
  background-color: var(--header-bg);

  .profileBar {
    background-color: var(--header-profile-bg);
    color: var(--header-profile-color);
    .name {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      padding: 16px 48px;
      font-size: 18px;
      flex-wrap: wrap;

      @media (max-width: 1920px) {
        font-size: 17px;
      }
      @media (max-width: 1750px) {
        font-size: 16px;
      }
      @media (max-width: 1200px) {
        padding: 16px 24px;
      }

      a {
        font-style: italic;
        font-weight: 600;
      }
    }
  }

  .paMenu {
    @media (max-width: 1400px) {
      display: none;
    }
  }
  .memberBar {
    background-color: var(--header-profile-bg);
    color: var(--header-profile-color);
    .items {
      display: flex;
      gap: 24px;
      padding: 0 48px;
      justify-content: flex-end;
      @media (max-width: 1400px) {
        padding: 16px;
        display: block;
      }
      .item {
        display: flex;
        align-items: center;
        gap: 16px;
        position: relative;
        padding: 16px 0;
        @media (max-width: 1400px) {
          padding: 8px 0 0 0;
          display: block;
          &:nth-child(2),
          &:nth-child(4) {
            display: none;
          }
          &:first-child {
            padding-top: 0;
          }
          & > span {
            display: inline-block;
            padding-right: 16px;
            padding-top: 8px;
            &:last-child {
              width: 100%;
            }
          }
        }
      }
      .separator {
        background-color: var(--header-profile-color);
        width: 2px;
        height: 100%;
      }
      .link {
        font-size: 12px;
      }
      .balance {
        color: var(--header-profile-color);
      }
    }
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 0 48px;

    @media (max-width: 1600px) {
      gap: 16px;
    }
    @media (max-width: 1500px) {
      gap: 8px;
    }
    @media (max-width: 1200px) {
      padding: 0 24px;
    }

    &.hasMobileMenu {
      border-bottom-left-radius: 0;
    }

    .barWrap {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: 1600px) {
      gap: 16px;
    }

    .img img {
      width: auto;
      height: 60px;
    }
  }
  .menu {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: 1920px) {
      font-size: 15px;
    }
    @media (max-width: 1750px) {
      font-size: 14px;
    }
    @media (max-width: 1600px) {
      gap: 16px;
    }

    .wrap {
      display: flex;
      align-items: center;
      gap: 16px;

      @media (max-width: 1550px) {
        gap: 8px;
      }
    }

    .item {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      // border-radius: 8px;
      text-transform: uppercase;
      color: var(--header-tab-color);

      &:hover,
      &.active {
        background-color: var(--active-header-tab-bg);
        color: var(--active-header-tab-color);
      }

      @media (max-width: 1500px) {
        padding: 12px;
      }
      @media (max-width: 1450px) {
        padding: 12px 10px;
      }
    }
  }
  .icons {
    display: flex;
    align-items: center;
    gap: 16px;
    color: var(--icon-color);
  }
  .outer {
    padding: 24px 0;
    @media (max-width: 1200px) {
      padding: 16px 0;
    }
    &.desktop {
      .burger {
        .menu {
          font-size: 20px;
        }
      }
      @media (max-width: 1400px) {
        .menu {
          .wrap {
            display: none;
          }
        }
        .logo {
          .selector {
            display: none;
          }
        }
      }
    }
  }
  .switchClub {
    .item {
      text-align: center;
      color: var(--card-title);
      background-color: var(--card-bg);
      display: block;
      width: 100%;
      border-bottom: 1px solid var(--input-border);
      &:hover {
        background-color: var(--mobile-menu-bg);
        &:first-child {
          color: var(--card-title);
          background-color: var(--card-bg);
        }
      }
    }
    .img {
      img {
        width: auto;
        height: 40px;
      }
      &:global(.oswaldsImg) img {
        height: 70px;
      }
      &:global(.maximesImg) img {
        height: 55px;
      }
    }
  }
}

:global(.fivehs) {
  .header {
    .logo {
      .img img {
        width: auto;
        height: 45px;
        @media (max-width: 1700px) {
          height: 40px;
        }
        @media (max-width: 1600px) {
          height: 35px;
        }
      }
    }
    .switchClub {
      .item {
        &:hover {
          background-color: var(--active-header-tab-bg);
          &:first-child {
            background-color: var(--card-bg);
          }
        }
      }
    }
  }
}

:global(.oswalds) {
  .header {
    .logo {
      .img img {
        width: auto;
        height: 60px;
      }
    }
  }
}

.mobileMenu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 400px;
  background-color: var(--mobile-menu-bg);
  box-shadow: 0 24px 24px 0 var(--floating-footer-active);
  transition: all 0.4s;
  overflow-y: auto;
  z-index: 20;
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  @media (max-width: 820px) {
    left: 0;
  }
  @media (max-width: 560px) {
    width: 100%;
  }

  .clubInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px;
    border-bottom: 1px solid var(--input-border);
  }

  .menu {
    display: block;
    .wrap {
      display: block;
      .item {
        border-bottom: 1px solid var(--input-border);
        width: 100%;
        // border-radius: 0;
        padding: 16px 16px;
      }
    }
  }
}

.footer {
  @media (max-width: 740px) {
    padding-top: 80px;
  }
  .floating {
    background-color: var(--floating-footer-bg);
    color: var(--floating-footer-color);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;

    .icon {
      padding: 12px;
      // border-radius: 8px;
      line-height: 1;
      &.active {
        background-color: var(--floating-footer-active);
      }
    }
  }
}

.bottomWrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: var(--static-footer-text);

  .copyright {
    text-align: right;
  }

  h4 {
    color: var(--static-footer-text);
  }

  @media (max-width: 600px) {
    display: block;
    .copyright {
      padding-top: 24px;
      text-align: left;
    }
  }
}
