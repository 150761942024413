:root {
    /* default colors */
    --white: #fff;
    --bronze: #62272E;
    --secondary-bronze: #7C4949;
    --pink: #E7CECE;
    --dark: var(--bronze);

    --secondary-pink: #F2EAD8;
    --secondary-white: #EBDDDD;
    --active-pink: #ECDEDE;
    --default-text: var(--bronze);
    --default-bg: var(--pink);

    --input-required: #FF0000;
    --input-valid: #00A13B;
    --toastify-color-light: var(--bronze);
    --toastify-text-color-light: var(--pink);
    --placeholder: var(--secondary-bronze);
    --modal-bg: rgba(98, 39, 46, 0.9);

    --header-bg: var(--pink);
    --header-profile-bg: var(--bronze);
    --mobile-menu-bg: var(--pink);
    --header-profile-color: var(--secondary-pink);
    --header-tab-color: var(--bronze);
    --active-header-tab-color: var(--bronze);
    --active-header-tab-bg: var(--active-pink);

    --icon-color: var(--bronze);
    --count-bg: var(--bronze);
    --count-color: var(--pink);

    --tab-bg: var(--bronze);
    --tab-color: var(--pink);
    --active-tab-bg: var(--pink);
    --active-tab-color: var(--bronze);
    --tab-title-color: var(--secondary-bronze);
    --tab-border-color: var(--secondary-bronze);
    --site-bgimg: var(--active-pink);

    --card-bg: var(--active-pink);
    --card-title: var(--secondary-bronze);
    --card-text: var(--bronze);

    --account-title-text: var(--bronze);
    --account-text: var(--bronze);

    --border-pink: #BF9FA1;
    --secondary-border-pink: var(--secondary-pink);
    --editor-link-border: var(--bronze);
    --editor-link-border-secondary: var(--bronze);
    --default-border: var(--bronze);
    --secondary-border: var(--secondary-bronze);
    --accordion-border: var(--secondary-bronze);
    --tertiary-border: var(--secondary-bronze);
    --input-border: var(--bronze);
    --special-border: var(--bronze);
    --special-border-width: 1px;
    --close-btn-border-width: 1px;
    --card-content-border-width: 0px;

    --input-bg: transparent;
    --checkbox-border: #BF9FA1;
    --active-checkbox-bg: var(--active-pink);
    --active-checkbox-border: var(--bronze);

    --btn-primary: var(--bronze);
    --btn-secondary: var(--pink);
    --btn-text: var(--secondary-pink);
    --btn-gold: var(--bronze);
    --btn-gold-text: var(--pink);
    --btn-solid: var(--bronze);
    --btn-solid-text: var(--pink);

    --popup-bg: var(--pink);
    --popup-body-bg: var(--pink);
    --confirm-popup-body-bg: var(--pink);
    --custom-popup-body-bg: transparent;
    --popup-header-bg: var(--bronze);
    --popup-header-color: var(--pink);
    --close-btn-hover-bg: var(--bronze);
    --close-btn-bg: transparent;

    --loader: var(--bronze);
    --load-more-bg: var(--pink);

    --sub-section-bg: var(--bronze);
    --sub-section-color: var(--pink);
    --intro-text: var(--bronze);
    --static-footer-text: var(--bronze);

    --adyen-dark: var(--active-pink);
    --adyen-light: var(--bronze);

    --loading-bg: var(--pink);
    --floating-footer-bg: var(--pink);
    --floating-footer-color: var(--bronze);
    --floating-footer-active: var(--active-pink);
    --skeleton-bg: linear-gradient(to right,
            rgba(98, 39, 46, 0.35) 46%,
            rgba(98, 39, 46, 0) 50%,
            rgba(98, 39, 46, 0.35) 54%) 50% 50%;

    --spinner-color: var(--bronze);
    --filter-bg: var(--active-pink);
    --highlight-text-color: var(--input-valid);
    --calendar-hover: var(--secondary-pink);
}

.fivehs {
    --red: #6C2B32;
    --gold: #DABF91;
    --cream: #F7EADA;
    --dark: #000;
    --secondary-pink: var(--cream);
    --secondary-white: var(--gold);
    
    --toastify-color-light: var(--gold);
    --toastify-text-color-light: var(--red);

    --default-bg: var(--red);

    --header-bg: var(--red);
    --mobile-menu-bg: var(--red);
    --header-profile-bg: var(--gold);
    --header-profile-color: var(--red);
    --header-tab-color: var(--cream);
    --active-header-tab-color: var(--red);
    --active-header-tab-bg: var(--gold);
    --default-text: var(--red);

    --icon-color: var(--cream);
    --count-bg: var(--cream);
    --count-color: var(--red);

    --tab-bg: var(--gold);
    --tab-color: var(--red);
    --active-tab-bg: var(--red);
    --active-tab-color: var(--cream);
    --tab-title-color: var(--cream);
    --tab-border-color: var(--gold);
    --site-bgimg: var(--red);

    --card-bg: var(--cream);
    --card-title: var(--red);
    --card-text: var(--red);

    --input-bg: var(--gold);
    --checkbox-border: var(--red);
    --active-checkbox-bg: var(--gold);
    --active-checkbox-border: var(--gold);
    --active-checkbox-color: var(--default-text);

    --account-title-text: var(--gold);
    --account-text: var(--red);

    --editor-link-border: var(--cream);
    --editor-link-border-secondary: var(--red);
    --default-border: var(--red);
    --secondary-border: var(--red);
    --accordion-border: var(--gold);
    --tertiary-border: var(--gold);
    --input-border: var(--red);
    --special-border: var(--gold);
    --border-pink: var(--gold);
    --secondary-border-pink: var(--red);

    --special-border-width: 1px;
    --card-content-border-width: 1px;

    --btn-primary: var(--red);
    --btn-secondary: transparent;
    --btn-gold: var(--gold);
    --btn-gold-text: var(--red);
    --btn-solid: var(--red);
    --btn-solid-text: var(--gold);

    --popup-bg: var(--gold);
    --popup-body-bg: var(--cream);
    --confirm-popup-body-bg: var(--cream);
    --custom-popup-body-bg: var(--cream);
    --popup-header-bg: var(--gold);
    --popup-header-color: var(--red);

    --loader: var(--cream);
    --load-more-bg: var(--gold);

    --sub-section-bg: var(--gold);
    --sub-section-color: var(--red);
    --intro-text: var(--cream);
    --static-footer-text: var(--cream);

    --adyen-dark: var(--gold);
    --adyen-light: var(--default-text);

    --loading-bg: var(--gold);
    --floating-footer-bg: var(--red);
    --floating-footer-color: var(--cream);
    --floating-footer-active: var(--gold);
    --skeleton-bg: linear-gradient(to right,
            rgba(218, 191, 145, 0.35) 46%,
            rgba(218, 191, 145, 0) 50%,
            rgba(218, 191, 145, 0.35) 54%) 50% 50%;

    --spinner-color: var(--cream);
    --filter-bg: var(--gold);
    --highlight-text-color: var(--gold);
    --calendar-hover: var(--secondary-pink);
}

.oswalds {
    --grey: #546065;
    --cream: #F9EED6;
    --beige: #F9F2E8;
    --black: #000;
    --dark: #000;
    --midgrey: #ccc;
    --secondary-white: var(--cream);
    --secondary-pink: var(--beige);
    --sub-section-bg: var(--cream);
    --sub-section-color: var(--grey);
    --bronze: var(--grey);

    --default-text: var(--black);
    --default-bg: var(--cream);
    --modal-bg: rgba(84, 96, 101, 0.9);

    --toastify-color-light: var(--cream);
    --toastify-text-color-light: var(--black);

    --header-bg: var(--cream);
    --mobile-menu-bg: var(--cream);
    --header-profile-bg: var(--grey);
    --header-profile-color: var(--beige);
    --header-tab-color: var(--black);
    --active-header-tab-color: var(--black);
    --active-header-tab-bg: var(--beige);

    --icon-color: var(--black);
    --count-bg: var(--grey);
    --count-color: var(--beige);

    --tab-bg: var(--grey);
    --tab-color: var(--cream);
    --active-tab-bg: var(--cream);
    --active-tab-color: var(--black);
    --tab-title-color: var(--black);
    --tab-border-color: var(--grey);
    --site-bgimg: var(--cream);

    --card-bg: var(--beige);
    --card-title: var(--black);
    --card-text: var(--black);
    --card-content-border-width: 0px;

    --editor-link-border: var(--grey);
    --editor-link-border-secondary: var(--grey);
    --default-border: var(--grey);
    --secondary-border: var(--grey);
    --accordion-border: var(--grey);
    --tertiary-border: var(--gold);
    --input-border: var(--grey);
    --special-border: var(--grey);
    --border-pink: var(--grey);
    --secondary-border-pink: var(--grey);
    
    --account-title-text: var(--black);
    
    --special-border-width: 1px;
    --close-btn-border-width: 0px;
    --close-btn-hover-bg: var(--grey);
    --close-btn-bg: var(--beige);

    --active-checkbox-bg: var(--cream);
    --active-checkbox-border: var(--grey);
    --active-checkbox-color: var(--grey);

    --popup-bg: var(--cream);
    --popup-body-bg: var(--beige);
    --confirm-popup-body-bg: var(--cream);
    --popup-header-bg: var(--grey);
    --popup-header-color: var(--beige);
    --custom-popup-body-bg: var(--beige);

    --btn-primary: var(--grey);
    --btn-secondary: transparent;
    --btn-gold: var(--grey);
    --btn-gold-text: var(--cream);
    --btn-solid: var(--grey);
    --btn-solid-text: var(--cream);

    --input-bg: var(--cream);

    --floating-footer-bg: var(--cream);
    --floating-footer-color: var(--black);
    --floating-footer-active: var(--beige);
    --loader: var(--cream);
    --load-more-bg: var(--cream);
    --skeleton-bg: linear-gradient(to right,
    rgba(84,96,101, 0.35) 46%,
    rgba(84,96,101, 0) 50%,
    rgba(84,96,101, 0.35) 54%) 50% 50%;

    --adyen-dark: var(--cream);
    --adyen-light: var(--grey);
    
    --loader: var(--grey);
    --filter-bg: var(--beige);
    --intro-text: var(--grey);
    --static-footer-text: var(--grey);
    --calendar-hover: var(--midgrey);
}

.Toastify__close-button--light {
    opacity: 1;
    color: var(--toastify-text-color-light);
}

.loggedIn {
    --toastify-toast-top: 185px;
}

* {
    margin: 0px;
    padding: 0px;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    background: var(--default-bg);
    transition: background-color 0.4s ease-in-out;
    color: var(--default-text);
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
}

body,
input,
textarea,
select {
    font-family: "Libre Baskerville";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

input[type="checkbox"],
input[type="radio"] {
    display: none;
}

img {
    max-width: 100%;
    border-style: none;
}

p {
    line-height: 1.5;
}

label {
    cursor: pointer;
}

a {
    display: inline-block;
}

a,
button {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    transition: all 0.4s ease;
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
}

a:active {
    background-color: transparent;
}

select,
map,
area,
a,
button {
    border: 0px;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

input[type="text"],
input[type="password"],
textarea {
    text-align: left;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

ul,
li {
    list-style: none;
}

select::-ms-expand {
    display: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input::-ms-clear {
    display: none;
}

input[type="password"]::-ms-clear,
input[type="password"]::-ms-reveal {
    display: none;
}

input,
textarea,
select {
    border: 0;
    outline: none;
    line-height: normal;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    color: var(--default-text);
}

textarea {
    resize: none;
}

body>iframe {
    display: none !important;
}

.Toastify__toast-container--bottom-center {
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
}

.Toastify__toast-container--bottom-center>div {
    margin-top: 10px;
}